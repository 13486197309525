import { Resource } from 'i18next'

export const enTranslations: Resource = {
  translation: {
    'language-selector': {
      title: 'Select a language'
    },
    header: {
      'about-us': 'About us',
      token: 'AMZ3 Token',
      roadmap: 'Roadmap',
      'contact-us': 'Contact Us',
      'white-paper': 'White Paper',
      marketplace: 'Want to be part!',
      catalog: 'Map',
      'buy-now': 'Buy now',
      certificate: 'My certificates',
      contact: "Contact us",
    },
    home: {
      title: 'Amazon Tree',
      subtitle: 'GREEN-TECHnology',
      text: 'Join us in the biggest digital transformation in support of the Amazon around the world',
      welcome: {
        "line1": "The future cannot be imagined",
        "line2": "without acting in the present.",
        video: "https://youtu.be/GrPYGzmAmpc",
        "video-label": "Watch our manifesto"
      },
      greentech: {
        subtitle: "We are a greentech for monitoring and conservation of forests around the world.",
        topic1: "Tokens generated from each monitored area",
        topic2: "Data recorded via Blockchain with more transparency",
        topic3: "Transactions in sustainable cryptocurrencies",
        topic4: "Complete immersion in an exclusive metaverse",
        topic5: "Constant monitoring via satellites"
      },
      future: {
        title1: "The future is not awaited.",
        title2: "The future is created.",
        "first-paragraph": "We are a greentech company for monitoring and conserving forests that has a complete ecosystem for tokenization of proprietary assets via blockchain, ensuring that every square meter acquired has its impact maximized positively for the environment and social economic prosperity.",
        "second-paragraph": "We exist for leading people and brands who see technology not only as a tool for business growth but as a force to drive the future we want through ESG.",
        "third-paragraph": "Because movements need to be led by those who have a real commitment to the longevity of businesses based on the balance of the world.",
        "fourth-paragraph": "In a scenario where the truth of companies has been increasingly questioned and practical actions are increasingly demanded by society amid uncertainties, insecurities, and pressure all over the world."
      },
      prosperity: {
        title1: "Where business prosperity meets",
        title2: "environmental prosperity.",
        "first-paragraph": "It is estimated that the forest absorbs around 2 billion tons of CO2 per year. This means that by protecting and conserving the Amazon, we are not only preserving a unique region but also actively combating climate change and ensuring a more stable future for the planet.",
        "second-paragraph": "Our ecosystem records the environmental and forest conservation service contracts for one or more land modules.",
        "third-paragraph": "The following are considered environmental and forest conservation services for lands in the Amazon - Brazil to be provided by Amazon Tree: Monitoring of Land Modules, as well as promoting initiatives for the maintenance of forest conservation in the areas registered on the Amazon Tree Platform."
      },
      token: {
        title1: "The lungs of the world",
        title2: "protected by impactful technology.",
        "first-paragraph": "Our ecosystem is funded through Amazon Tree's collections.",
        cta: "Read more"
      },
      "metaverse": {
        "line1": "Participate in virtual events, exclusive lectures, and workshops on",
        "line2": "sustainability and technological innovation.",
        "cta": "Enter the metaverse"
      }
    },
    about: {
      title: 'Welcome to',
      subtitle: 'Amazon Tree',
      'first-paragraph':
        'Amazon Tree is a privately held SA committed to the conservation of the Amazon. It is known that the conservation of the Amazon is an urgent and collective mission that requires the involvement of people and companies. Only through joint efforts and genuine commitment can we protect this precious ecosystem and ensure a sustainable future for generations to come.',
      'second-paragraph':
        'The connection between people and companies on this journey is powerful. Companies that prioritize conservation can inspire their employees and customers to become agents of change, encouraging a culture of environmental responsibility and community engagement. On the other hand, engaged individuals can influence companies to adopt more sustainable and responsible practices, creating a virtuous cycle of commitment to the Amazon.',
      'third-paragraph':
        'In an innovative and disruptive way, Amazon Tree has built an integrated ecosystem that involves acquisition of forestry assets, standardization of smart contracts, tokenization, provision of conservation services, registration and issuance of Certificates in NFT format with security and transparency so that companies and people can participate in initiatives that promote forest conservation.',
      'fourth-paragraph': '',
      'fifth-paragraph':
        'The Ecosystem is financed through transparent and scalable NFTs, generating shared prosperity by promoting the development of actions aimed at environmental preservation through the digital transformation of an asset. By connecting preservation projects with companies and individuals seeking compensation or wishing to contribute to environmental preservation, it becomes accessible to the numerous groups fighting for the environment to have access to new opportunities.'
    },
    "about-token": {
      "green-is": {
        title: "Green is the new black"
      },
      "token-info": {
        "block1": {
          "title": "What is",
          "subtitle": "UCF AMZ3",
          "first-paragraph": "When contracting Amazon Tree's services, you will receive a UCF (Forest Conservation Unit) Certificate regarding the area (m²) registered in the Land Module in the form of a token.",
          "second-paragraph": "The UCF Certificate is publicly issued as a Smart Contract, which allows tracking of each step of the process in a certified and transparent manner with the Blockchain.",
          'video': 'https://www.youtube.com/watch?v=DihVyTLAPpc',
          'video-label': 'Watch the Quick Guide',
        },
        "block2": {
          "title": "UCF AMZ3 Certificate",
          "subtitle1": "Digital token,",
          "subtitle2": "real impact.",
          "first-paragraph": "As a disruptive ESG initiative involving cutting-edge technology, with the creation and registration of the UCF Certificate tokenized on the Blockchain through Smart Contracts, it is possible to activate the Amazon Tree Ecosystem, providing transparency and trust.",
          "second-paragraph": "Every UCF Certificate token has referential information. One of them is the Contract Address that trace the origin of the token. In this way, you can validate such information on our website."
        },
        "block3": {
          "first-paragraph": "The UCF (Forest Conservation Unit) Certificate regarding the area (m²) registered no token. In other words, by acquiring it, you participate in the ecosystem that promotes conservation, carbon stock increase, and circular economy.",
          "second-paragraph": "With this, tons of carbon dioxide are avoided, reducing impacts related to global warming.",
          "cta": "I want to acquire"
        }
      },
      "collection": {
        "title": "Collections",
        "subtitle": "Amazon Tree",
        "first-paragraph": "Our first SUMAÚMA GENESIS collection offers an innovative and tangible way to conserve the Amazon Rainforest. The Sumaúma echoes the strength and majesty of the Amazon Rainforest. It is known as the 'tree of life', housing an infinity of species in its branches and roots. This grandiose tree, one of the tallest and oldest in the forest, serves as inspiration for our collection.",
        "second-paragraph": "Just as the imposing Sumaúma is essential to harboring the Amazonian biodiversity, each person who acquires an AMZ3 UCF from the Sumaúma I collection plays a fundamental and irreplaceable role in our noble mission to conserve and protect the forest for future generations.",
        "cta": "Acquire your AMZ3 UCF"
      },
      "payment": {
        "title": "Payment",
        "subtitle1": "How to pay through",
        "subtitle2": "debit card, PIX or boleto",
        "first-paragraph": "Through your computer or mobile browser, go to the desired Collection page, choose the area, navigate to the checkout screen, fill out the information and select the payment option 'Real(R$) or Dollar($)'. After completing the transaction, the NFT will be sent to the informed Wallet and will be available in 'Collections' in your wallet.",
        "second-paragraph": "The transaction is publicly performed as a Smart Contract, enabling the tracking of each step of the process in a certified and transparent manner."
      },
      "payment-solana": {
        "title": "How to pay through",
        "subtitle1": "Solana Wallet",
        "subtitle2": "(Web3)",
        "topic1": {
          "title": "Set up your Wallet",
          "text": [
            "There are different types of Wallets (Crypto Wallet) for Solana. You can connect with various wallets.",
            "Generally, wallets are available through a browser extension and/or mobile app.",
            "highlight::We recommend using the Phantom wallet.",
            "If you don't have a crypto wallet, select the option: \"Create Wallet\".",
            "highlight::⚠️ ATTENTION: You will receive 12 words. This is your \"Secret Recovery Phrase\", your private key. These words will be used to recover your wallet or log in on a new device."  
          ]
        },
        "topic2": {
          "title": "Connect your Wallet to the platform",
          "text": [
            "Through your computer's browser or mobile app, select the desired wallet and connect it.",
            "highlight::⚠️ ATTENTION: It is not possible to connect the Wallet through the default mobile browser, only through the Phantom app browser or another of your preference."
            ]
        },
        "topic3": {
          "title": "Add Balance to Wallet",
          "text": [
            "If you don't have a balance, access your Phantom app, click buy, select the Solana cryptocurrency, enter the desired amount, and choose the payment method. After this step, the balance will be available in Sol in your wallet.",
            "highlight::Done! Now you have a Solana balance to make your purchase."
          ]
        },
        "topic4": {
          "title": "Choose your AMZ3 UCF",
          "text": [
            "At this stage, the registration of your token on the Blockchain will be created. Through your computer's browser or the Phantom app installed on your mobile, go to the 'MAP', choose the monitoring area, navigate to the checkout screen, fill out the information, and select the 'Solana' payment option.",
            "highlight::After completing the transaction, the NFT will be available in \"Collections\" in your wallet.",
            "The transaction is publicly performed as a Smart Contract, enabling the tracking of each step of the process in a certified and transparent manner.",
          ]
        }
      }
    },
    co2: {
      title: 'CO2 and its role in the current scenario',
      subtitle:
        'Understand the impacts and how you can make a difference. Impacts in Brazil and the World',
      'global-warming': {
        title: 'Global warming',
        description:
          'Increase in global average temperatures, leading to extreme weather events.'
      },
      'biodiversity-loss': {
        title: 'Biodiversity Loss',
        description:
          'Extinction of flora and fauna species due to the degradation of natural habitats.'
      },
      'hydrological-changes': {
        title: 'Hydrological Changes',
        description:
          'Changes in rain cycles, affecting agriculture and available water resources.'
      }
    },
    problems: {
      title: 'Issues',
      subtitle: 'Impacts on fauna',
      subtitle2: 'and flora',
      'first-paragraph':
        'Over 50 billion tons of carbon dioxide are released annually into our atmosphere, which is one of the Greenhouse Gases (GHG) that will increase the temperature of our planet in the coming years.',
      'second-paragraph':
        'This is a problem that has been addressed through the initiative of carbon offsetting by companies worldwide. ',
      'third-paragraph':
        'A survey conducted with 30 companies by the Quintessa team showed that 100% of them are interested in engaging with solutions for "carbon emission reduction."',
      'fourth-paragraph':
        'However, one of the major barriers within the current framework is the lack of transparency in tracking the capture and allocation of funds for preservation projects, making it difficult to attract investments and discouraging the expansion of these initiatives, which suffer from a lack of significant financial incentives.'
    },
    nft: {
      title: 'Solutions',
      subtitle:
        'Generating Shared Prosperity Through Real-World Digital Transformation',
      subtitle2: 'The Ecosystem is funded through Amazon Tree NFT collections'
    },
    faq: {
      title: 'FAQ',
      'first-item': {
        header: 'How will the areas be monitored?',
        text: 'O monitoramento é realizado com a aplicação de conceitos como Digital Twin através de dados coletados de Satélites, Inventários associados à Inteligência Artificial e Machine Learning para ações preditivas e corretivas.'
      },
      'second-item': {
        header: 'How will the AMZ3 Metaverse work?',
        text: 'Com a digitalização das áreas tokenizadas, vamos colocar as pessoas dentro da maior floresta tropical do mundo, um ambiente em que nunca estiveram, a floresta Amazônica através do Metaverso.'
      },
      'third-item': {
        header: 'What is AMZ3 UCF?',
        text: 'Cada AMZ3 UCF equivale 1 unidade de conservação florestal. Ao adquirir o NFT AMZ3 UCF, é possível participar do ecossistema que promove a conservação e o incremento do estoque de carbono. O NFT AMZ3 UCF é o Certificado de Conservação Florestal referente à quantidade de área (m²) registrada no mesmo. As emissões são limitadas à área total de propriedade da Amazon Tree SA.'
      },
      'fourth-item': {
        header: 'Why an NFT AMZ3?',
        text: 'Tratando-se de uma iniciativa ESG disruptiva que envolve tecnologia de ponta, com a criação e inscrição do NFT em Blockchain através de Smart Contracts, é possível ativar o Ecossistema da AMZ3 dando início a um ciclo de prosperidade compartilhada através da preservação da floresta.'
      },
      'fifth-item': {
        header: 'How to query the exclusivity of the NFT AMZ3?',
        text: 'Todo NFT possui informações referenciais. Uma delas é o Contract Address que traz a origem do NFT. Desta forma, poderá validar em nosso site tais informações.'
      }
    },
    roadmap: {
      title: 'Roadmap',
      events: [
        {
          title: 'October 2021',
          description: 'Definition of the business model',
          position: 'top'
        },
        {
          title: 'October 2022',
          description:
            'Incorporation of the area (15,000,000 m²) and creation of Amazon Tree SA.',
          position: 'bottom'
        },
        {
          title: 'April 2023',
          description: 'Area tokenization',
          position: 'top'
        },
        {
          title: 'May 2023',
          description: 'Realization of the Forest Inventory',
          position: 'top'
        },
        {
          title: 'June 2023',
          description: 'Platform Integration with Blockchain',
          position: 'bottom'
        },
        {
          title: 'July 2023',
          description: 'Implementation of Corporate Governance',
          position: 'top'
        },
        {
          title: 'August 2023',
          description: 'Incorporation of area (15,000,000 m²).',
          position: 'bottom'
        },
        {
          title: 'September 2023',
          description:
            'Implementation of sustainability, biodiversity and carbon stock indicators. Implementation of the monitoring methodology through digitization.',
          position: 'top'
        },
        {
          title: 'October 2023',
          description:
            'Launch of the AMZ3 Genesis collection (NFT collection limited to founding and co-founding partners)',
          position: 'top'
        },
        {
          title: 'December 2023',
          description:
            'Incorporation of 30,000,000 m² of native forest in the Amazon region.',
          position: 'bottom'
        }
      ]
    },
    'contact-us': {
      title: 'Contact us',
      subtitle1: 'Be a',
      subtitle2: 'certified',
      subtitle3: 'partner',
      form: {
        name: 'Name',
        email: 'Email',
        subject: 'Subject',
        message: 'Message',
        submit: 'Submit',
        'error-messages': {
          name: 'Name is required',
          email: 'Email is required',
          subject: 'Subject is required',
          message: 'Message is required'
        }
      }
    },
    footer: {
      home: 'Home',
      problems: 'Issues',
      nft: 'Products',
      roadmap: 'Roadmap',
      about: 'About',
      solution: 'Solution',
      faq: 'FAQ',
      'white-paper': 'White paper',
      'follow-us': 'Follow us',
      rights: '©2023 | Amazon Tree® | All rights reserved'
    },
    marketplace: {
      title: 'AMZ3 Platform',
      subtitle: 'Amazon Tree Collections',
      text: 'The Ecosystem is funded through Amazon Tree NFT Collections in a transparent and scalable way. The first stage of the investment plan contemplates the acquisition of 90,000,000 m² of areas in the Amazon region by December 2023. It is possible to participate in the largest Digital Transformation on the planet for the preservation of the Amazon Forest by acquiring the AMZ3 NFTs.',
      'first-info-card': '15.000.000m²',
      'second-info-card': 'Native Forest',
      'third-info-card': 'Location - Rio Preto da Eva',
      'fourth-info-card':
        'More than 20,000 tons of CO2 removed from the atmosphere every year',
      'nft-info-text':
        'Each AMZ3 UCF is equivalent to 1 forest conservation unit. By acquiring the NFT AMZ3 UCF, it is possible to participate in the ecosystem that promotes the conservation and increase of the carbon stock. Representing for organizations or people up to 10 tons of CO2 avoided in 1 year. The NFT AMZ3 UCF is the Forest Conservation Certificate referring to the amount of area (m²) registered in it.',
      'btn-how-buy': 'Want to be part',
      'btn-more-info': 'Learn more',
      'first-info-text': 'launch year',
      'second-info-text': 'available trees',
      'third-info-text': 'of m² purchased',
      'fourth-info-text':
        'm² of native forest incorporated in the Amazon region.',
      'payment-info-title':
        'How to pay your NFT Credit/Debit Card, PIX or Boleto',
      'payment-info-description':
        'Through the browser on your computer or cell phone, go to the page of the desired Collection, choose the NFT, navigate to the checkout screen, fill in the information and select the payment option “Real(R$) or Dollar($)“ . After carrying out the transaction, the NFT will be sent to the informed Wallet and will be available in “Collections“ in your wallet. The transaction is carried out publicly as a Smart Contract, enabling the tracking of each stage of the process in a certified and transparent manner.',
      'how-to-pay-title': 'How to pay your NFT Wallet Solana (Web3)',
      'how-to-pay-first-step': {
        title: 'Set up your Wallet',
        description:
          'There are different types of Wallet (Crypto Wallet) for Solana. You can connect with multiple wallets. We recommend using the Phantom Wallet. Wallets are usually available through a browser extension and/or mobile app.',
        subDescription: ''
      },
      'how-to-pay-second-step': {
        title: 'Plug it in your wallet',
        description: `Through the browser on your computer or the application on your cell phone, select the desired wallet and connect it. If you do not have a crypto wallet, select the option: “Create Wallet“. You will receive 12 words. This is your “Secret Recovery Phrase”.`,
        subDescription:
          'This is your private key. These words will be used to recover your wallet or log in on a new device.'
      },
      'how-to-pay-third-step': {
        title: 'Add Balance',
        description: `If you don't have a balance, access your Phantom app, click buy, select the Solana cryptocurrency, enter the desired amount and choose the payment method. After this step, the balance will be available in Sol in your wallet.`,
        subDescription: `That's it!😉🤩  Now you have a balance in Solana to make your purchase.`
      },
      'how-to-pay-fourth-step': {
        title: 'Choice your NFT',
        description: `In this step, your NFT record will be created on the Blockchain. Through your computer's browser or the Phantom application installed on your cell phone, go to the desired Collection page, choose the NFT, navigate to the checkout screen, fill in the information and select the payment option "Solana". After carrying out the transaction, the NFT will be available under “Collections“ in your wallet. The transaction is carried out publicly as a Smart Contract, enabling the tracking of each stage of the process in a certified and transparent manner.`,
        subDescription: `It is not possible to connect Wallet through the mobile phone's default browser, only through the Phantom app browser or another browser of your choice.`
      }
    },
    "catalog-tutorial": {
      "title": {
        "one": "Just a few steps away from",
        "two": "obtaining your certification"
      },
      "steps": {
        "one": "Navigate the map and select the areas you wish to conserve",
        "two": "Review the area information and add to cart",
        "three": "Create your account and verify your email",
        "four": "Complete the payment with your wallet or credit card and receive your UCF token"
      }
    },
    catalog: {
      'nfts-list': 'Token Ideas',
      'nfts-cart-title': 'Your tokens',
      'nft-card-detail-line': 'Line',
      'nft-card-detail-column': 'Column',
      'nft-card-detail-in-process': `Token is minting`,
      'nft-card-detail-copy-token-success': 'Copy to clipboard',
      'nft-card-detail-copy-owner-success': 'Copy to clipboard',
      'nft-card-detail-edition': 'Edition',
      'nft-card-detail-area': 'Area',
      'nft-card-detail-value': 'Value',
      'nft-card-total-quantity': 'Quantity of tokens:',
      'nft-card-total-value': 'Total:',
      'nft-card-total-checkout-btn': 'Checkout',
      'nft-resume-check': 'Summary',
      'nft-resume-close': 'Close summary',
      'tab-map': 'Map',
      'tab-summary': 'Summary',
      'tab-tutorial': 'Tutorial',
      'map-popup-button-add': 'Add token',
      'map-popup-button-remove': 'Remove token',
    },
    cart: {
      title: 'Cart summary',
      'area-total': 'Total area',
      'water-total': 'Water Vapor produced:',
      'nft-card-coordinates': 'Coordinates',
      'nft-card-coordinates-top-left': 'Top Left:',
      'nft-card-coordinates-top-right': 'Top Right:',
      'nft-card-coordinates-bottom-left': 'Bottom Left:',
      'nft-card-coordinates-bottom-right': 'Bottom Right:'
    },
    checkout: {
      title: 'Enter your data',
      'crypto-pre-checkout-exception': 'Something wrong happened',
      'signature-exception': 'Something wrong happened',
      'order-exception': 'Something wrong happened',
      'form-name': 'Name/Company',
      'form-email': 'Email',
      'form-wallet': 'Wallet',
      'form-wallet-empty-term': `I am aware that I must provide the PUBLIC KEY to my Wallet to receive the UCF Certificate in NFT format.`,
      'form-payment-label': 'Choose which currency to pay in',
      'form-payment-info': `*For payments in Boleto Bancário and Pix,the currency to be chosen must be Real (BRL)`,
      'form-submit-btn': 'Checkout',
      'form-complete-sign-up': 'I am aware that I need to complete the full sign-up before finalizing the hiring process.',
      'form-error-messages': {
        name: 'Name is required',
        email: 'Email is required',
        wallet: 'Please fill the wallet or check the warning',
        'sign-up': 'Please check the warning',
        currency: 'Select a currency'
      }
    },
    success: {
      title: 'Hiring made with success!',
      description: `Congratulations on your hiring! Thank you for choosing Amazon Tree. We know that each UCF Certificate in NFT form is unique and special, and we are excited to be part of this journey with you.`,
      'home-btn': 'Home',
      'dash-btn': 'View certificates',
      'wallet-btn': 'Wallet'
    },
    fail: {
      title: 'Unable to complete your hiring',
      description: `Unfortunately, we had a problem processing your payment. To secure the NFT, you can try again with another payment method.`,
      'retry-btn': 'Try again'
    },
    'sign-in': {
      title: 'Sign in',
      form: {
        email: 'Email',
        password: 'Password',
        'submit-btn': 'Login',
        'error-messages': {
          email: 'Email is required',
          password: 'Password is required'
        }
      },
      'forget-password': 'Forget password',
      'forget-password-error-message': 'Fill in the email',
      'forget-password-success-message':
        'Reset password email sent successfully',
      'sign-up': 'Sign up',
      'request-error': `Error! Wasn't possible to sign in`,
      'or': 'OR',
      'google': 'Continue with Google',
      'microsoft': 'Continue with Microsoft',
    },
    'sign-up': {
      title: 'Sign up',
      'title-step-2': '(Address)',
      title1: 'Almost there!',
      title2: 'Unlock all the features of your account.',
      body1: 'Complete your registration to access the client area, download your certificate, and enjoy all the benefits of your AmazonTree account.',
      form: {
        name: 'Full Name',
        email: 'Email',
        'confirm-email': 'Confirm your email',
        password: 'Password',
        'confirm-password': 'Confirm your password',
        cpf: 'CPF',
        country: 'Country',
        'zip-code': 'Zip code',
        street: 'Address',
        district: 'Address 2',
        city: 'City',
        state: 'State (Province)',
        number: 'Number',
        complement: 'Complement',
        phone: 'Phone',
        'continue-btn': 'Continue',
        'submit-btn': 'Complete registration',
        'error-messages': {
          name: 'Name is required',
          email: 'Email is required',
          'confirm-email': 'Confirm your email',
          'email-match': 'The emails did not match',
          password: 'Password is required',
          'confirm-password': 'Confirm your password',
          'password-match': 'The passwords did not match',
          'password-strength':
            'The password needs at least 8 characters, one uppercase letter, one lowercase letter and one special character',
          country: 'Country is required',
          'zip-code': 'Zip code is required',
          street: 'Address is required',
          district: 'Address 2 is required',
          city: 'City is required',
          state: 'State is required',
          number: 'Number is required',
          cpf: 'CPF is required',
          phone: 'Phone is required'
        },
        notification: {
          title: 'User Registration',
          message1: 'Email to validation was sent to',
          message2a: 'Validation must be completed within',
          message2b: 'minutes.',
          message3: 'If it is not completed within this time, you will need to sign up again.',
        }
      },
      'request-error': `Error! Wasn't possible to create your account`
    },
    notification: {
      'button-types': {
        'try-again': 'Try again',
        wallet: 'Wallet',
        forward: 'Continue',
        home: 'Go to home',
      }
    },
    loading: 'Loading...',
    'privacy-policy': {
      title: 'Privacy Policy',
      description:
        'We use cookies and other similar technologies to improve your experience on our services. By using our services, you are aware of this functionality. Consult our ',
      link: 'Privacy Policy',
      submit: 'Confirm'
    },
    'change-password': {
      title: 'Create new password',
      form: {
        password: 'Password',
        'confirm-password': 'Confirm your password',
        'submit-btn': 'Submit',
        'error-messages': {
          password: 'Password is required',
          'confirm-password': 'Confirm your password',
          'password-match': 'The passwords did not match',
          'password-strength':
            'The password needs at least 8 characters, one uppercase letter, one lowercase letter and one special character'
        }
      },
      'success-message': 'Password changed with success',
      'request-error': `Error! Wasn't possible to change your password`
    },
    'user-menu': {
      dashboard: 'Dashboard',
      trigger: 'User profile',
      'change-password': 'Change password',
      'sign-in': 'Sign in',
      'sign-out': 'Sign out'
    },
    wallet: 'Add Wallet',
    'reset-password': {
      title: 'Reset password',
      form: {
        email: 'Email',
        'submit-btn': 'Submit',
        'error-messages': {
          email: 'Email is required'
        }
      },
      'success-message': 'Reset password email sent successfully',
      'error-message': 'Fail tot sent the email'
    },
    'verify-phone': {
      title: 'Code sent to your phone',
      form: {
        code: 'Verification code',
        'submit-btn': 'Submit',
        'error-messages': {
          code: 'Verification code is required'
        }
      },
      'try-again-btn': 'Resend code',
      'resend-code-success-message': 'Verification code sent to your phone',
      'resend-code-error-message': 'Fail to resend verification code',
      'success-message': 'Verification code checked successfully',
      'error-message': 'Fail to check the verification code'
    },
    carousel: {
      banners: {
        solution: {
          title: 'Are you part of the solution or the problem?',
          'first-description':
            'Do you consider the conservation of the Amazon Forest important?',
          'second-description':
            'What have you done to make a real, positive impact?'
        },
        environment: {
          title: 'Impacts on ESG',
          subtitle: 'Environmental',
          'first-description': 'Breathing Life',
          'second-description': 'through Conservation'
        },
        social: {
          title: 'Impacts on ESG',
          subtitle: 'Social',
          'first-description': 'Strengthened Communities,',
          'second-description': 'Secured Future'
        },
        governance: {
          title: 'Impacts on ESG',
          subtitle: 'Governance',
          'first-description': 'Transparency',
          'second-description': 'and Reliability'
        }
      }
    },
    terms: {
      error: 'Error to load the terms'
    },
    mapbox: {
      'check-area-error': 'Error to check the area availability'
    },
    dashboard: {
      menu: {
        home: 'Back to home',
        account: 'My account',
        dashboard: 'Dashboard',
        recent: 'Recent activity',
        contracts: 'My contracts',
        orders: 'My orders',
        certificates: 'My certificates',
        conservation: 'Conservation data',
        'satellite-images': 'Satellite images',
        help: 'Help and support',
        tutorials: 'Tutorials and guide',
        language: {
          label: 'Language',
          select: 'Select a language...'
        }
      },
      'change-profile': {
        title: 'My account',
        form: {
          name: {
            label: '',
            placeholder: 'Name',
            'error-message': 'Name is required'
          },
          email: {
            label: '',
            placeholder: 'Email',
            'error-message': 'Email is required'
          },
          country: {
            label: '',
            placeholder: 'Country',
            'error-message': 'Country is required'
          },
          zipCode: {
            label: '',
            placeholder: 'CEP',
            'error-message': 'CEP is required'
          },
          'first-address': {
            label: '',
            placeholder: 'Address 1',
            'error-message': 'Address 1 is required'
          },
          number: {
            label: '',
            placeholder: 'Number',
            'error-message': 'Number is required'
          },
          'second-address': {
            label: '',
            placeholder: 'Address 2',
            'error-message': 'Address 2 is required'
          },
          complement: {
            label: '',
            placeholder: 'Complement',
            'error-message': 'Complement is required'
          },
          city: {
            label: '',
            placeholder: 'City',
            'error-message': 'City is required'
          },
          state: {
            label: '',
            placeholder: 'State',
            'error-message': 'State is required'
          },
          cpf: {
            label: '',
            placeholder: 'CPF',
            'error-message': 'CPF is required'
          },
          phone: {
            label: '',
            placeholder: 'Phone',
            'error-message': 'Phone is required'
          },
          instagram: {
            label: '',
            placeholder: 'Instagram'
          },
          facebook: {
            label: '',
            placeholder: 'Facebook'
          },
          discord: {
            label: '',
            placeholder: 'Discord'
          },
          linkedIn: {
            label: '',
            placeholder: 'LinkedIn'
          },
          'cancel-btn': 'Cancel',
          'submit-btn': 'Save',
          'request-success': `Profile changed with success`,
          'request-error': `Wasn't possible to change the profile`
        }
      },
      'my-certificates': {
        cards: {
          vapor: 'Vapor produced',
          area: 'Conservation area',
        },
        notification: "Notifications",
        alert: "You have certificates to claim.",
        title: "My certificates",
        filter: {
          retrieved: 'Retrieved',
          pending: 'Pending',
          all: 'All'
        },
        nft: {
          edition: 'Edition',
          minted: 'Minted',
          expiration: 'Valid until',
        }
      },
      buttons:{
        rescue:'Claim token',
        cancel:'Cancel',
        send:'Send',
        seemore:'See more',
        'download-certificate': 'Download Certificate (PDF)'
      },
      rescue: {
        label: 'Inform your Solana wallet address',
        placeholder: 'Your wallet',
      },
      'conservation-data': {
        'total-area': 'Total area',
        'biomass': 'Total biomass',
        'vapor': 'Water vapor produced',
        'carbon': 'Carbon sequestered',
        'sidebar': {
          'select-token': 'Select the UCF token',
          'header': 'Data referring to {days} days since registration ({date})',
          'ndvi': 'Normalized Difference Vegetation Index / NDVI',
          'download-report': 'Download report'
        },
        help: {
          'biomass': 'Biomass represents the total mass of all living organisms in the forest. It plays a crucial role in maintaining ecological balance by storing energy and nutrients, supporting biodiversity, and acting as a source of organic matter that nourishes the ecosystem.',
          'vapor': 'The forest produces water vapor through the process of transpiration, where plants release moisture into the atmosphere. This process is essential for regulating the local and global climate, as it helps form clouds, supports the water cycle, and maintains rainfall patterns.',
          'carbon': 'Forests play a vital role in mitigating climate change by sequestering carbon from the atmosphere. Trees absorb carbon dioxide during photosynthesis and store it in their biomass, helping to reduce greenhouse gas concentrations and limit global warming.',
          'ndvi': 'The NDVI is a numerical indicator that uses satellite imagery to assess live green vegetation in an area. By measuring the difference between near-infrared (which vegetation strongly reflects) and red light (which vegetation absorbs), NDVI provides a value ranging from -1 to +1. Higher values indicate healthier and denser vegetation. This index is crucial for monitoring plant health, vegetation coverage, and ecological changes in the forest.'
        },
        selector: {
          satellite: "Satellite",
          ndvi: "NDVI"
        },
        'mobile-disclaimer': 'For the complete experience, please access this page from your computer.'
      }
    },
    orderStatus:{
      CREATED : 'Created',
      DISCARDED : 'Discarded',
      VALIDATING_PAYMENT : 'Validating payment',
      PAYMENT_REFUSED : 'Payment refused',
      PAYMENT_APPROVED : 'Payment approved',
      CREATING_TOKEN : 'Creating token',
      TOKEN_MINTED : 'Token minted',
      TOKEN_MOVED_TO_USER : 'Token moved to user',
    },
    helper: {
      'coming-soon': 'Coming soon'
    },
    'owner-nfts': {
      'instructions': 'Select your observation area to locate on the map'
    },
    buyContract: {
      agreementTerm: 'Agreement term',
      iAgreeToThe: 'I agree to the',
      termsAndConditions: 'terms and conditions',
      confirm:'Confirm'
    }
  }
}
